import "@/styles/globals.scss";
import Footer from "@/layouts/Footer";
import Header from "@/layouts/Header";
import { Poppins } from "next/font/google";
import { getGlobal } from "@/functions/getGlobal";
import { setLocale } from "@/hooks/useLocale";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700"],
  variable: "--popins",
});

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const global = getGlobal();
  setLocale(pageProps.locale);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [router]);

  return (
    <>
      <style jsx global>
        {`
          html {
            font-family: ${poppins.style.fontFamily};
          }
        `}
      </style>
      <Header />
      <main className="sm:pt-0">
        <Component {...pageProps} global={global} />
      </main>
      <Footer />
    </>
  );
}
