import React from "react";

export const ctx = React.createContext({ locale: "en" });

export const useLocale = () => {
  return ctx.locale;
};

export const setLocale = (locale = "en") => {
  ctx.locale = locale;
};

export default useLocale;
